import React from "react"
import queryString from "query-string"
import {useStaticQuery, graphql} from "gatsby"

import Card from "../../components/card"
import { FormFieldInput, FormFieldTextArea, FormFieldFile  } from "../../components/form-fields"

import ApplicationFormLogic from "../../components/application-form-logic"
import ApplicationFormContainer from "../../components/application-form-container"

export class HealthyCommunityForm extends ApplicationFormLogic {
  render() {
    return (
      <ApplicationFormContainer
        data={this.props.data}
        state={this.state}
        closeModal={this.closeModal}
        handleSubmit={this.handleSubmit}
        handleSave={this.handleSave}
        >
        <Card sectionTitle="Project" sectionSubtitle="">
          <FormFieldInput
            labelText="Project Name"
            name="projectName"
            type="text"
            value={this.state.projectName}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldInput
            labelText="Neighborhood Name"
            name="neighborhoodName"
            type="text"
            value={this.state.neighborhoodName}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="Describe the geographic community and the targeted
                      resident population that is the focus of the initiative.
                      What health challenges and disparities does this
                      community face?"
            name="projectCommunity"
            value={this.state.projectCommunity}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="Explain how the initiative was developed and which
                      area(s) of community health are being addressed through
                      the initiative (e.g. environmental, physical, behavioral
                      and/or mental health)."
            name="projectAreas"
            value={this.state.projectAreas}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="Describe the initiatives program model: the key
                      strategies, partners, funding sources, expected and
                      actual outcomes to date. How does the program incorporate
                      equity, collaboration and sustainability into the
                      community health program model?"
            name="projectModel"
            value={this.state.projectModel}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="Describe how the community – including residents and
                      local organizations and institutions were engaged in
                      identifying the health issues, developing the strategies
                      and working on implementation. Is there a plan to
                      sustain this community-led work beyond this particular
                      program?"
            name="projectEngagement"
            value={this.state.projectEngagement}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="What were the major obstacles or challenges to
                      implementation, and how have they been overcome?"
            name="projectObstacles"
            value={this.state.projectObstacles}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="Provide details on the program budget."
            name="projectBudget"
            value={this.state.projectBudget}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />
        </Card>


        <Card sectionTitle="Media" sectionSubtitle={this.props.data.mediaSectionSubhead}>
          <FormFieldFile
            labelText="Supporting Materials"
            acceptedFileTypes={['image/*', 'application/pdf']}
            handleProcessFile={this.handleProcessFile}
            handleProcessFiles={this.handleProcessFiles}
            handleRemoveFile={this.handleRemoveFile}
            files={this.state.files}
            disabled={this.state.submitted}
            name="uploadPhotos"
            helpText="Only image files (.png, .jpg, etc.) and PDFs are accepted. Files must be a maximum of 5MB in size."
          />

          <FormFieldInput
            labelText="Video Link"
            name="videoLink"
            type="text"
            value={this.state.videoLink}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            helpText="Link to a video hosted on a video-sharing service like Dropbox or YouTube."
          />
        </Card>

        <Card sectionTitle="Contact" sectionSubtitle="">
          <FormFieldInput
            labelText="Name"
            name="applicantName"
            type="text"
            value={this.state.applicantName}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldInput
            labelText="Organization"
            name="applicantOrganization"
            type="text"
            value={this.state.applicantOrganization}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldInput
            labelText="Title"
            name="applicantTitle"
            type="text"
            value={this.state.applicantTitle}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="Address"
            name="applicantAddress"
            type="text"
            value={this.state.applicantAddress}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldInput
            labelText="Phone Number"
            name="applicantPhone"
            type="text"
            value={this.state.applicantPhone}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldInput
            labelText="Email"
            name="applicantEmail"
            type="text"
            value={this.state.applicantEmail}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldInput
            labelText="How did you learn about the opportunity to apply for this award?"
            name="applicantReferral"
            type="text"
            value={this.state.applicantReferral}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />
        </Card>
      </ApplicationFormContainer>
    );
  }
}

export default props => {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: {eq: "applications/healthy-community.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1000, maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  data.applicationType="healthy-community"
  data.fields=[
   'projectName', 'neighborhoodName',
   'projectCommunity', 'projectAreas',
   'projectModel', 'projectEngagement',
   'projectObstacles', 'projectBudget',
   'videoLink',
   'applicantName', 'applicantOrganization', 'applicantTitle',
   'applicantAddress', 'applicantPhone', 'applicantEmail',
   'applicantReferral'
  ]

  data.title="Healthy Community Award"
  data.titleText="The Blue Cross Blue Shield of Illinois Healthy Community Award"
  data.awardDescription="The Blue Cross Blue Shield of Illinois Healthy Community Award recognizes a successful community- based effort to address the health of a low-to-moderate income neighborhood in the Chicago metropolitan area through creative and collaborative strategies."
  data.awardLink="https://lisc-cnda.org/awards/healthy-community"
  data.headerImagePath="applications/healthy-community.jpg"
  data.headerImageLink="https://map.lisc-cnda.org/2019/cnda/street-medicine-service-outreach"
  data.headerImageProject="Street Medicine Service Outreach"
  data.headerImageDescription=", a 2019 Healthy Community Award winner"
  data.mediaSectionSubhead="Up to 10 supporting materials or a video link can be included but are not required."
  data.optionalMedia=true

  return (
    <HealthyCommunityForm
      data={data}
      uuid={queryString.parse(props.location.search).uuid}
  />
  )
}
